export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      // {
      //   title: 'Lista Segnalazioni',
      //   route: 'second-page',
      //   icon: 'FileIcon',
      // },
    ],
  },
]
